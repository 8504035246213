<template>
  <!--  <div class="payment-page">-->
  <!--    <div class="container mx-auto">-->
  <!--      <h1 class="text-center font-bold text-4xl mb-10 text-base-100">Төлбөр төлөх сонголтоо хийнэ үү!</h1>-->
  <!--      <div class="flex gap-4 lg:flex-row sm:flex-col xs:flex-col justify-between align-center">-->
  <!--        <router-link to="/payment/qpay"-->
  <!--                     class="card shadow-2xl bg-base-100 lg:card-side border-base-100 border text-primary-content p-5 cursor-pointer transition-all duration-300 ease-in-out hover:bg-opacity-40 hover:border-accent">-->
  <!--          <div class="flex items-center">-->
  <!--            <img :src="Qpay" alt="qpay" class="w-1/3 pay-img mr-5">-->
  <!--            <div class="font-medium">QPay</div>-->
  <!--          </div>-->
  <!--        </router-link>-->

  <!--        <a href="javascript:void(0)"-->
  <!--           class="card shadow-2xl bg-base-100 lg:card-side border-base-100 border text-primary-content p-5 transition-all duration-300 ease-in-out">-->
  <!--          <div class="flex items-center">-->
  <!--            <img :src="Card" alt="card" class="w-1/3 pay-img mr-5">-->
  <!--            <div class="font-medium">Картаар</div>-->
  <!--          </div>-->
  <!--        </a>-->

  <!--        <router-link to="/payment/transfer"-->
  <!--                     class="card shadow-2xl bg-base-100 lg:card-side border-base-100 border text-primary-content p-5 cursor-pointer transition-all duration-300 ease-in-out hover:bg-opacity-40 hover:border-accent">-->
  <!--          <div class="flex items-center">-->
  <!--            <img :src="Bank" alt="bank" class="w-1/3 pay-img mr-5">-->
  <!--            <div class="font-medium">Шилжүүлэг</div>-->
  <!--          </div>-->
  <!--        </router-link>-->
  <!--      </div>-->
  <!--    </div>-->
  <div class="page center">
    <div class="container mx-auto payment">
      <div class="payment-page">
        <h1 class="text-center page-title" style="padding-top: 40px;color: hsla(0, 0%, 100%, .8)">Төлбөр төлөх сонголтоо
          хийнэ үү</h1>
        <div
            class="flex items-center justify-center align-middle grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-10">
          <router-link :to="{
                      path: '/payment/transfer',
                      query:  {amount: this.$route.query.amount }
                    }" class="card transfer bg-base-100 shadow-2xl" style="background: #121212">
            <div class="overlay-w"></div>
            <div class="circle-w">
              <svg style="color:#D98A19;" xmlns="http://www.w3.org/2000/svg" class="h-3/5" fill="none"
                   viewBox="0 0 24 24"
                   stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4"/>
              </svg>
            </div>
            <p class="card-title">ДАНСААР <br/> ШИЛЖҮҮЛЭХ</p>
          </router-link>

          <router-link :to="{
                      path: '/payment/qpay',
                      query:  {id: this.$route.query.id }
                    }" class="card qpay bg-base-100 shadow-2xl" style="background: #121212">
            <div class="overlay-w"></div>
            <div class="circle-w">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-3/5" fill="none" viewBox="0 0 24 24" stroke="#3B6CB7">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M12 4v1m6 11h2m-6 0h-2v4m0-11v3m0 0h.01M12 12h4.01M16 20h4M4 12h4m12 0h.01M5 8h2a1 1 0 001-1V5a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1zm12 0h2a1 1 0 001-1V5a1 1 0 00-1-1h-2a1 1 0 00-1 1v2a1 1 0 001 1zM5 20h2a1 1 0 001-1v-2a1 1 0 00-1-1H5a1 1 0 00-1 1v2a1 1 0 001 1z"/>
              </svg>
            </div>
            <p class="card-title">QPAY</p>
          </router-link>

          <router-link to="/payment/card" class="card wemaxcard bg-base-100 shadow-2xl" style="background: #121212">
            <div class="overlay-w"></div>
            <div class="circle-w">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-3/5" fill="none" viewBox="0 0 24 24" stroke="#59B08B">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                      d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z"/>
              </svg>
            </div>
            <p class="card-title">Купон</p>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Qpay from "../../assets/qpay.png";
import Bank from "../../assets/bank.png";
import Card from "../../assets/card.png";
import {mapGetters} from "vuex";

export default {
  name: "index",
  components: {},
  computed: {
    ...mapGetters(['app']),
  },

  data() {
    return {
      Qpay,
      Bank,
      Card
    }
  }
}
</script>
